.btn-github {
	color: #fff;
	background-color: #444;
	border-color: rgba(0, 0, 0, 0.2);
	padding: 0.75rem;
}

.btn-github:focus, .btn-github.focus {
	color: #fff;
	background-color: #2b2b2b;
	border-color: rgba(0, 0, 0, 0.2)
}

.btn-github:hover {
	color: #fff;
	background-color: #2b2b2b;
	border-color: rgba(0, 0, 0, 0.2)
}

.btn-github:active, .btn-github.active, .open>.dropdown-toggle.btn-github {
	color: #fff;
	background-color: #2b2b2b;
	border-color: rgba(0, 0, 0, 0.2)
}

.btn-github:active:hover, .btn-github.active:hover, .open>.dropdown-toggle.btn-github:hover, .btn-github:active:focus, .btn-github.active:focus, .open>.dropdown-toggle.btn-github:focus, .btn-github:active.focus, .btn-github.active.focus, .open>.dropdown-toggle.btn-github.focus {
	color: #fff;
	background-color: #191919;
	border-color: rgba(0, 0, 0, 0.2)
}

.btn-github:active, .btn-github.active, .open>.dropdown-toggle.btn-github {
	background-image: none
}

.btn-github.disabled, .btn-github[disabled], fieldset[disabled] .btn-github, .btn-github.disabled:hover, .btn-github[disabled]:hover, fieldset[disabled] .btn-github:hover, .btn-github.disabled:focus, .btn-github[disabled]:focus, fieldset[disabled] .btn-github:focus, .btn-github.disabled.focus, .btn-github[disabled].focus, fieldset[disabled] .btn-github.focus, .btn-github.disabled:active, .btn-github[disabled]:active, fieldset[disabled] .btn-github:active, .btn-github.disabled.active, .btn-github[disabled].active, fieldset[disabled] .btn-github.active {
	background-color: #444;
	border-color: rgba(0, 0, 0, 0.2)
}

.btn-github .badge {
	color: #444;
	background-color: #fff
}