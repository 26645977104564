.cohortStudents__sort {
    margin: 2rem 0 0 0;
}

.cohortStudents {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}

.bookColumn {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 2rem 0 0 0;
}

.bookColumn__header {
    border: 1px dotted rgb(50, 67, 50);
    background-color: var(--tertiaryDark);
    padding: 0.3rem 0.25rem;
    text-align: center;
    min-width: 4rem;
    border-radius: 0.33rem;
    font-size: 0.8rem;
    color: var(--darkText);
    margin: 0 0 0.8rem 0;
    box-shadow: 0.25rem 0.25rem 0.25rem rgb(156, 156, 156);
}

.bookColumn__name {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.bookColumn__studentCount {
    margin-left: auto;
    display: flex;
}

.bookColumn__projects {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    font-size: smaller;
}

.projectColumn {
    margin: 0 0.5rem;
    display: flex;
    flex-direction: column;
}

.projectColumn__header {
    border: 1px dotted rgb(50, 67, 66);
    background-color: var(--tertiaryLight);
    padding: 0.25rem 0.25rem;
    text-align: center;
    min-width: 2rem;
    border-radius: 0.33rem;
    color: var(--darkText);
    margin: 0 1rem;
    box-shadow: 0.25rem 0.25rem 0.25rem rgb(156, 156, 156);
}

.projectColumn__header--assessment {
    background-color: var(--secondary);
}

.projectColumn__students {
    flex: 1;
    height: 80vh;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0.5rem auto 0 auto;
    align-items: center;
}

.active_cohorts {
    padding: 0;
    margin: 0 5rem 0 0;
}

.active_cohorts__list {
    padding: 0;
    margin: 0.25rem 0 0 0;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    justify-content: space-evenly;
}

.active_cohorts__cohort {
    /* border: 1px solid var(--tertiaryDark); */
    padding: 0.25rem 1rem;
}

.cohortActions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0.75rem 0 0 0;
}

.cohortAction {
    flex: 1;
}

.cohortAction--large {
    flex: 3;
}
