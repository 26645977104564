.tabs {
    list-style: none;
    position: relative;
    text-align: left;
    display: flex;
}

.tabs li {
    float: left;
    display: block;
}

.tabs input[type="radio"] {
    position: absolute;
    top: 0;
    left: -9999px;
}

.tabs label {
    display: block;
    padding: 14px 21px;
    border-radius: 2px 2px 0 0;
    font-size: 0.8rem;
    font-weight: normal;
    text-transform: uppercase;
    background: #dae3ff;
    cursor: pointer;
    position: relative;
    top: 4px;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.tabs label:hover {
    background: #bfccee;
}

.tabs .tab-content {
    z-index: 2;
    display: none;
    overflow: hidden;
    width: 90%;
    font-size: 1rem;
    line-height: 25px;
    top: 53px;
    left: 0;
    background: #f4f7ff;
    padding: 2rem;
    position: absolute;
    min-height: 45rem;
}

.tabs [id^="tab"]:checked+label {
    top: 0;
    padding-top: 17px;
    background: #f4f7ff;
}

.tabs [id^="tab"]:checked~[id^="tab-content"] {
    display: block;
}

p.link {
    clear: both;
    margin: 380px 0 0 15px;
}

p.link a {
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    color: #fff;
    padding: 5px 10px;
    margin: 0 5px;
    background-color: #724883;
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
}

p.link a:hover {
    background-color: #522764;
}