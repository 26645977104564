.repos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.repo {
    flex-basis: 25%;
    border: 1px dotted darkgoldenrod;
    background-color: lightgoldenrodyellow;
    padding: 1rem 2rem;
    margin: 1rem 0;
}

.repo__date {
    margin-top: 1.5rem;
    font-size: smaller;
    font-style: oblique;
}