.course {
    min-width: 12rem;
    max-width: 15rem;
    position: relative;
    margin: 0.5rem 1rem;
    padding: 0rem 1rem 0.5rem 1rem;
    border: 1px dotted slateblue;
    display: flex;
    flex-direction: column;
    background-color: rgb(254, 256, 255);
    box-shadow: 0.25rem 0.25rem 0.5rem 0 rgb(180, 179, 179);
    border-radius: 0.33rem;
}

.course__index {
    margin-left: auto;
    color: rgb(183, 183, 183);
}

.container--courseList {
    margin: 1rem 3rem;
}

.course__header {
    display: flex;
    flex-wrap: nowrap;
}

.course__filter {
    display: flex;
    flex-wrap: nowrap;
}

.course__description {
    margin: 0.5rem 0 0.33rem 0;
}