@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/violet.css';
@import url('./css/radix-dropdown.css');
@import url('./css/radix-popover.css');

button {
    all: unset;
}

.student {
    border: 1px solid #E9DACB;
    padding: 0 0.33rem 0.3rem 0.5rem;
    border-radius: 0.15rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1), -2px -2px 8px rgba(255, 255, 255, 0.7);
    background: radial-gradient(circle 3rem at center, #ffffff, #f7f4ef);
    transition: all .2s ease-in-out;
    margin: 0.15rem 0.15rem;
    z-index: 1;
}

.student--mini {
    width: 5rem;
}

.student--regular {
    width: 11rem;
}

.student__name {
    color: rgb(5, 50, 95);
    font-weight: 400;
 }

.student__name:hover {
    color: rgb(30, 93, 156);
    text-decoration: underline;
    cursor: pointer;
}

.student__duration {
    position: absolute;
    top: -0.1rem;
    right: 0.1rem;
    color: #afafaf;
    font-size: xx-small;
    font-family: inherit;
    height: 1rem;
    display: inline-flex;
}

.student__info {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 0;
}

.student__history {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.student__score {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: firebrick;
    color: rgb(255, 209, 209);
    padding: 0.5rem 1.25rem;
    font-size: 1.3rem;
    text-align: center;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.student__details {
    font-size: 0.7rem;
    display: flex;
}

.student__github {
    flex: 2;
}

.student__cohort {
    flex: 1;
}

.persona {
    display: flex;
    flex-direction: row;
}

.section--persona {
    padding: 0 1rem;
}

.persona__myers-briggs {
    flex: 3;
}

.persona__bfi {
    flex: 2;
}

.personality-- {
    background-color: rgb(219, 234, 237);
}

.personality--Analyst {
    background-color: #e0c8e9;
}

.personality--Sentinel {
    background-color: #aedced;
}

.personality--Explorer {
    background-color: #f9e7c1;
}

.personality--Diplomat {
    background-color: #c2f0dd;
}


.student--takingAssessment {
    border: 2px solid var(--started);
}

.student--assessmentReviewNeeded {
    border: 3px solid var(--ready);
    animation: attention 4s linear infinite;
}

.student--assessReviewIncomplete {
    border: 2px solid var(--incomplete);
}

.student--assessReviewComplete {
    border: 2px solid var(--complete);
}

@keyframes attention {
    0% {
        border-color: red;
    }

    50% {
        border-color: rgb(255, 164, 164);
    }

    100% {
        border-color: red;
    }
}

.student__actions {
    display: none;
    flex-wrap: nowrap;
    border-bottom: 1px dotted darkgreen;
}


.action {
    flex: 0 0 20%;
}

.student__coreskills {
    display: grid;
    grid-template-columns: auto auto auto auto;
    font-size: smaller;
    margin: 0 0 1rem 0;
    margin-top: auto;
}

.student__tags {
    margin-top: auto;
    border-top: 1px dotted darkslategray;
    padding: 0.5rem 0 0 0;
    font-size: smaller;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}


.studentnote {
    display: flex;
    flex-direction: column;
    flex: 0 1 20%;
    position: relative;
    margin: 0.5rem 1rem;
    padding: 1rem 1rem 0.5rem 1rem;
    border: 1px dotted slateblue;
    background-color: rgb(254, 256, 255);
    box-shadow: 0.25rem 0.25rem 0.5rem 0 rgb(180, 179, 179);
    border-radius: 0.33rem;
}

.studentnote__header {}

.studentnote__footer {
    border-top: 1px dotted slategray;
    display: flex;
    margin: 1rem 0 0 0;
    padding: 0.33rem 0;
    color: rgb(149, 149, 149);
    justify-content: space-between;
    font-size: 0.75rem;
}

.footer__score {
    margin: 0;
    line-height: 0;
}

.student__project {
    font-size: smaller;
    padding: 0 0 0.33rem 0;
}

.student__book {
    font-size: smaller;
}

.student--tag {
    padding: 0.1rem 0.33rem;
    background-color: #e3c0ce;
    color: black;
    border-radius: 0.25rem;
    margin: 0.2rem 0.33rem;
    font-size: 0.7rem;
}


.tag__name {
    font-size: 0.55rem;
    padding-left: 0;
}


.overlay--student {
    animation: createBox .25s;
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255);
    z-index: 2;
}

@keyframes createBox {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

.close {
    position: relative;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    overflow: hidden;
    border-radius: 50%;
    border: 1px dotted white;
}

.close:hover {
    border: 1px dotted rgb(62, 62, 62);

}

.close:hover::before, .close:hover::after {
    background: #1ebcc5;
}

.close::before, .close::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: rgb(131, 130, 130);
}

.close::before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.close::after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.close.hairline::before, .close.hairline::after {
    height: 1px;
}

.svg {
    cursor: pointer;
    margin-left: 0.33rem;
    height: 0.9rem
}

.svg--large {
    height: 1.5rem
}

.section--notes {
    height: fit-content;
    min-height: 15rem;
    max-height: 30rem;
    overflow: scroll;
    border: 1px dotted lightsteelblue;
}

.dialog--note {
    min-height: 20rem;
    height: fit-content;
}

.note {
    border-top: 1px solid rgb(191, 231, 231);
    padding: 0.4rem 1rem;
    display: flex;
}

.note__text {
    font-size: 0.9rem;
    flex: 20;
    text-align: left;
}

.note__date {
    font-size: 0.7rem;
    flex: 4;
    text-align: center;
}

.note__author {
    font-size: 0.7rem;
    flex: 4;
    text-align: center;
}

.section--proposals {
    padding: 0 2rem;
}

.rt-SelectItem:hover {
    background-color: rgb(187, 187, 249);
}

.student__assessmenticon {
    position: absolute;
    bottom: 0;
    right: 0;
    text-decoration: none;
}

.student__assessmenticon:hover {
    cursor: pointer;
}