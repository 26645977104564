.note--AssessmentReview {
    background-color: #ffd1c1;
    border-left: 4px solid #f8f8f8;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1rem;
}

.note--GeneralProgress {
    background-color: rgb(241, 252, 249);
    border-left: 4px solid #f8f8f8;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1rem;
}

.note--MentalHealth {
    background-color: #f2f7c8;
    border-left: 4px solid #f8f8f8;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1rem;
}

.note--BehavioralIssue {
    background-color: #fbdcfc;
    border-left: 4px solid #f8f8f8;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1rem;
}

.noteDialog__close {
    position: absolute;
    top: 0.33em;
    right: 0.5em;
    font-size: 0.75rem;
}