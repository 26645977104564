@import url("https://fonts.googleapis.com/css?family=Comfortaa|Patua+One");
@import './tabbar.css';
@import './table.css';
@import './icons.css';
@import './buttons.css';
@import './card.css';
@import './dropdown.css';
@import './tabs.css';
@import './position.css';

/*Typography
--------------------------------------------------------------*/

:root {
    --primary: #CE8147;
    --secondary: #ECDD7B;
    --tertiaryDark: #CDE7BE;
    --tertiaryLight: #D3E298;
    --lightText: snow;
    --darkText: #214E34;
    --started: rgb(233, 237, 0);
    --ready: rgb(231, 52, 20);
    --incomplete: rgb(175, 22, 165);
    --complete: rgb(49, 225, 96);
}


body, button, input, select {
    color: #1b1b1b;
    font-family: "Comfortaa", Arial, sans-serif;
    font-size: 0.9em;
    line-height: 1.5;
    padding: 0;
    margin: 0;
}

body {
    background-color: #e5e5f7;
    background-image: radial-gradient(#767dfb 0.5px, transparent 0.5px), radial-gradient(#767dfb 0.5px, #fdfdfd 0.5px);
    background-size: 20px 20px;
    background-position: 0 0, 10px 10px;
}

main {
    margin: 0 2rem;
}

@media (max-width: 780px) {
    body {
        font-size: 0.9em;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Patua One", serif;
    letter-spacing: 2px;
}

h4 {
    margin-block-end: 0.33rem;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

dialog {
    padding: 1rem;
    min-width: 15rem;
    min-height: 5rem;
    z-index: 12;
    width: 70%;
    top: 10rem;
}

.invisible {
    display: block;
    background-color: unset;
    border: unset;
    min-height: 1.5rem;
    padding: 0.5rem;
}

.visible {
    display: block;
    min-height: 1rem;
    padding: 0.5rem;
}

.error {
    border: 1px dotted rgb(107, 21, 4);
    background-color: rgb(255, 229, 228);
}

.feedback {
    border: 1px dotted cadetblue;
    background-color: rgb(218, 230, 247);
}


.view {
    margin: 1rem 3rem;
}

@media (min-width: 768px) {
    .view {
        margin: 1rem 5rem;
    }
}

@media (min-width: 1000px) {
    .view {
        margin: 1rem 10rem;
    }
}

.text--mini {
    font-size: small;
}

.text--xmini {
    font-size: smaller;
}

.fakeLink {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
    border: none;
    cursor: pointer;
    background-color: inherit;
    font-size: inherit;
}

.prompt {
    font-weight: bold;
}

.activeLink {
    color: var(--darkText);
    font-weight: 900;
    text-decoration: none;
    background-color: var(--tertiaryDark);
    border-radius: 1rem;
}

.readonly {
    background-color: rgb(214, 226, 242);
    padding: 0.5rem;
    border-radius: 0.3rem;
}

.rightAlign {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    margin: 0 0 2rem 0;
}

.small {
    font-size: smaller;
}

.nav-item {
    list-style-type: none;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control--small {
    padding-left: 0.4rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-right: 0.4rem;
    height: calc(1.5em + .5rem);
    font-size: 0.8rem;
    line-height: 1.25;
}

.form-control--row {
    flex: 3
}

.form-control--dialog {
    width: 95%;
}

.form-group {
    margin: 1rem 0;
}

.form-group--row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

.label--row {
    flex: 1;
    padding: 0.75rem 0 0 0;
}

.label--smallrow {
    flex: 1;
    padding: 0.5rem 0 0 0;
}

fieldset {
    min-width: 0;
    margin: 0;
    border: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

.important {
    color: firebrick;
    font-size: 1rem;
}

.clickable {
    cursor: pointer;
}

.delete {
    margin: 0 0 0 0.33rem;
}

.hidden {
    display: none;
}

html, body, #root {
    height: 100%
}


/*
    All credit for following CSS to Lena Design
    https://lenadesign.org/2021/05/03/css-earth-with-the-moon-moving-around-on-the-svg-path/
*/

.container {
    position: relative;
}

.clouds {
    position: absolute;
    background-color: #041c2f;
    width: 200px;
    height: 40px;
    border-radius: 50px;
    top: -45px;
    left: 90px;
    box-shadow: -140px 40px #072137, 140px 40px #072137, 20px 40px #041c2f, 20px 80px #041c2f, 180px 120px #072137, 20px 120px #041c2f, 70px 160px #041c2f, -180px 160px #041c2f, -290px 200px #072137, -10px 200px #072137, -120px 200px #041c2f, -140px 240px #041c2f;
}

.clouds:before {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.3);
    top: -10px;
    box-shadow: -100px 100px rgba(255, 255, 255, 0.3), 130px 230px rgba(255, 255, 255, 0.3), 50px 310px rgba(255, 255, 255, 0.3), -130px 215px rgba(255, 255, 255, 0.3);
}

.clouds:after {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    left: 50px;
    top: 20px;
    box-shadow: 100px 100px rgba(255, 255, 255, 0.2), -100px 240px rgba(255, 255, 255, 0.2);
}

.planet {
    position: relative;
    width: 200px;
    height: 200px;
    background-color: #00a5cf;
    border-radius: 50%;
    overflow: hidden;
}

.planet:before {
    content: "";
    position: absolute;
    background-color: #368715;
    width: 100px;
    height: 25px;
    border-radius: 20px;
    left: 15px;
    box-shadow: 60px 25px #00a5cf, 25px 25px #368715, -25px 25px #368715, 50px 50px #368715, -15px 50px #368715, 90px 75px #00a5cf, 30px 75px #368715, -15px 75px #368715, -25px 0px #368715;
    top: 100px;
}

.planet:after {
    content: "";
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    top: 0;
    box-shadow: inset -10px 10px rgba(0, 0, 0, 0.2);
}

.moon {
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: #d6d6d6;
    box-shadow: inset -5px 5px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    top: -135px;
    left: -175px;
    overflow: hidden;
    offset-path: path("M280.25,191c38.55,0,74.78,5.44,102,15.32C409.18,216.08,424,228.93,424,242.5s-14.82,26.42-41.74,36.18C355,288.56,318.8,294,280.25,294s-74.78-5.44-102-15.32c-26.91-9.76-41.74-22.61-41.74-36.18s14.83-26.42,41.74-36.18c27.24-9.88,63.47-15.32,102-15.32m0-1c-79.95,0-144.76,23.51-144.76,52.5S200.3,295,280.25,295,425,271.49,425,242.5,360.19,190,280.25,190Z");
    animation: move 2s linear infinite;
}

.moon:before, .moon:after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    box-shadow: inset 2px 2px rgba(0, 0, 0, 0.2);
    background-color: #e5e5e5;
}

.moon:before {
    top: 5px;
    left: 25px;
}

.moon:after {
    top: 35px;
    left: 10px;
}

@keyframes move {
    to {
        offset-distance: 50%;
    }
}

.up {
    position: absolute;
    background-color: #00a5cf;
    width: 200px;
    height: 100px;
    border-radius: 100px 100px 0 0;
    top: 0;
    overflow: hidden;
}

.up:before {
    content: "";
    position: absolute;
    background-color: #368715;
    width: 100px;
    height: 25px;
    border-radius: 20px;
    left: -10px;
    box-shadow: 60px 25px #00a5cf, 25px 25px #368715, -25px 25px #368715, 50px 50px #368715, -5px 50px #368715, 90px 75px #00a5cf, 30px 75px #368715, -10px 75px #368715;
}

.up:after {
    content: "";
    position: absolute;
    width: 200px;
    height: 100px;
    border-radius: 100px 100px 0 0;
    top: 0;
    box-shadow: inset -10px 10px rgba(0, 0, 0, 0.2);
}