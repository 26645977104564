.studentList {
    margin: 1rem 3rem;
}

.studentList__student {
    display: grid;
    grid-template-columns: 1fr 5fr 3fr;
    margin-bottom: 0.33rem;
}

.studentList__options {
    margin-bottom: 2rem;
    flex-direction: row;
    display: flex;
}

.cohorts__header {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
}

.header--studentList {
    font-size: large;
    font-weight: bold;
}

.button--commit {
    margin: 0 0 0 1rem;
}

.button--collect {
    margin-left: auto;
    margin-right: 5rem
}
