.status__separator {
    border-top: 1px dotted slateblue;
    margin: 0 1rem 0 1rem;
}

.status__date {
    font-size: smaller;
    margin-top: 0.5rem;
    color: rgb(136, 155, 173);
}

.status {
    margin: 1rem 0 0 1rem;
    padding: 0.5rem;
}

.status:hover {
    background-color: rgb(247, 224, 208);
}

.button--capstoneStage {
    font-size: smaller;
    margin: 0 0.5rem;
}

.button--revoke {
    margin-left: auto;
}


.proposals {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.proposal {
    padding: 1rem;
    border: 1px dotted darkslateblue;
    margin: 0 0.25rem;
}

.proposalStatuses {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.proposalStatus {
    font-size: small;
}