.sliders {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0 2rem;
}

.slider__header {
    display: inline-block;
    margin-block-start: 1rem;
    margin-block-end: 0.33rem;
    margin-left: 0.5rem;
}

.slider__range {
    -webkit-appearance: none;
    appearance: none;
    height: 5px;
    width: 90%;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider:hover {
    opacity: 1;
}
