.cohorts {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 5rem;
    font-size: small;
    justify-content: space-evenly;
}

/* .cohorts::after {
    content: '';
    flex: 0 1 20%;
    margin: 0.5rem 1rem;
    padding: 0rem 1rem 0.5rem 1rem;
} */


.cohort {
    display: flex;
    flex-direction: column;
    width: 25%;
    max-width: 25%;
    position: relative;
    margin: 0.5rem 0;
    padding: 0rem 1rem 0.5rem 1rem;
    border: 1px dotted slateblue;
    background-color: rgb(254, 256, 255);
    box-shadow: 0.25rem 0.25rem 0.5rem 0 rgb(180, 179, 179);
    border-radius: 0.33rem;
}

.cohort--mine {
    background-color: lightgoldenrodyellow;
}

.cohort__header {
    margin: 2.25rem 0 0.5rem 0;
    font-size: 1rem;
}

.cohort__header--details {
    margin: 0.5rem 0;

}

.cohort__header:hover {
    color: rgb(106, 26, 122);
    text-decoration: underline;
    cursor: pointer;
}

.cohort__dates {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0 0 2rem 0;
}

.cohort__join {
    height: 1rem;
    width: 4rem;
    position: absolute;
    top: 0.5rem;
    right: -0.75rem;
    cursor: pointer;
}

.cohort__footer {
    border-top: 1px dotted slategray;
    display: flex;
    margin: 3rem 0 0 0;
    padding: 0.33rem 0;
    color: rgb(149, 149, 149);
    justify-content: space-between;
    margin-top: auto;
    font-size: 0.75rem;
}

.slack {
    width: 1rem;
}

.cohort__coaches {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 0.5rem;
}

.cohort__courses {
    display: flex;
    margin: 0 0 1rem 0;
}

.cohort__links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 0 1rem 0;
    font-size: 0.75rem;
    justify-items: center;
}

@media (max-width: 1000px) {
    .cohort__links {
        display: grid;
        grid-template-rows: 1fr 1fr;
        padding: 0 0 1rem 0;
        font-size: 0.75rem;
    }
}

.instructor--badge {
    padding: 0.33rem;
    background-color: rgb(244, 215, 161);
    color: black;
    border-radius: 0.25rem;
    margin: 0.2rem 0.25rem;
    font-size: smaller;
    max-width: 10rem;
    text-align: center;
}

.course--badge {
    padding: 0.33rem;
    background-color: rgb(251, 230, 252);
    color: rgb(135, 135, 135);
    border-radius: 0.25rem;
    margin: 0.2rem 0.25rem;
    font-size: smaller;
    min-width: 5rem;
    text-align: center;
}

.course--badge.active {
    background-color: rgb(106, 55, 108);
    color: rgb(255, 255, 255);
}

.icon--slack--cohort {
    height: 1rem;
    width: 1rem;
    cursor: pointer;
    margin: 0 0 0 0.35rem;
}

.search {
    position: relative;
}

.search__results {
    z-index: 5;
    position: absolute;
    width: 88%;
    top: 2.40rem;
    background-color: rgb(247, 250, 250);
    border: 1px dotted lightskyblue;
    padding: 1rem;
}

.student--results {
    padding: 0.3rem;
}

.student--results:hover {
    background-color: rgb(214, 230, 230);
}

.student--found {
    padding: 0.2rem 0 0.2rem 0.5rem;
    background-color: rgb(214, 230, 230);
    margin: 0.2rem 0;
    border-radius: 0.1rem;
    border: 1px dashed rgb(180, 208, 208);
    font-size: 0.8rem;
}

.student--found:hover {
    background-color: rgb(180, 208, 208);
    cursor: pointer;
}

.cohort__details {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.cohort__detail {
    flex: 1;
    margin: 0 3rem;
}

.cohort__detail--medium {
    flex: 1.5;
}

.cohort__detail--large {
    flex: 2;
}

@media (max-width: 1000px) {
    .cohort__details {
        display: block;
    }
}

.cohort__urls {
    margin: 0 2rem 0 0;
}

.cohort__studentList {
    overflow-y: scroll;
    max-height: 20rem;
}

.overlay--cohort {
    animation: createBox .25s;
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255);
    z-index: 2;
}

@keyframes createBox {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}