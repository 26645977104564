.NoteIconButton {
    position: absolute;
    margin: 0;
    padding: 0;
    bottom: 0;
    right: 0;
    font-family: inherit;
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
    background-color: white;
}

.NoteIconButton:hover {
    cursor: pointer;
}

.rt-BaseButton.rt-variant-solid:where(:focus-visible) {
    box-shadow: none;
}
