.course--detail, .book--detail, .project--detail {
    margin: 1rem 3rem;
}

.course__header {
    display: flex;
    flex-wrap: nowrap;
}

.coursedetail__header {
    font-size: 1.75rem;
}

.project__info {
    padding: 1rem;
}

.course__books, .book__projects, .courses {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.courses {
    margin: 1rem 4rem;
}

.book__asssessment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid rgb(191, 213, 180);
    padding: 0.33rem 0.5rem;
    margin: 0 0 0.25rem 0;
    background-color: rgb(247, 255, 243);
}

.book__asssessment:hover {
    padding: 0.33rem 0.6rem;
    background-color: rgb(235, 255, 226);
    cursor: pointer;
    text-decoration: underline;
    color:blue;
}

.course__book, .book__project {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid rgb(214, 223, 235);
    padding: 0.33rem 0.5rem;
    margin: 0 0 0.25rem 0;
    background-color: rgb(243, 249, 255);
}

.book__project--deprecated {
    background-color: rgb(253, 219, 213);
}

.book__project--group {
    background-color: rgb(238, 216, 250);
}

.course__book:hover, .book__project:hover {
    border: 1px solid rgb(202, 215, 233);
    padding: 0.33rem 0.6rem;
    background-color: rgb(234, 244, 254);
    cursor: pointer;
    text-decoration: underline;
    color:blue;
}

.book__project--deprecated:hover {
    background-color: rgb(250, 203, 195);
}

.book__project--group:hover {
    background-color: rgb(234, 202, 251);
}


.book__footer, .project__footer, .course__footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 0.5rem;
}
