.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
}

.card--row {
    -ms-flex-direction: row;
    flex-direction: row;
}

.card--padded {
    padding: 3rem;
}

.card-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0 1.25rem 0.8rem 1.25rem;
}

.card-title {
    margin-bottom: 0.75rem;
}