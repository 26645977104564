.toast {
    top: 5rem;
    right: 6rem;
}

.toast>.body {
    font-size: 1.25em;
}

.dashboard {
    display: flex;
    flex-direction: column;
    padding: 0 3rem;
    height: 95vh;
}

.dashboard--student {
    display: block;
    padding: 3rem 3rem;
    line-height: 1.7rem;
}

.dashboard--overview {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 3rem 3rem;
    line-height: 1.7rem;
    justify-content: space-evenly;
}


.info {
    border: 1px solid rgb(185, 220, 255);
}

.info__date {
    margin: 0 0 1rem 0;
}

.info__header {
    background-color: azure;
    padding: 1rem;
    margin-block-start: 0;
}

.info__body {
    padding: 1rem;
}

.studentDashboard__header {
    display: flex;
    justify-content: space-between;
}

.studentDashboard__icons {
    margin: 1.75rem 0 0 2rem;
}

.studentAccounts {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 0 2rem 0;
}

.studentAccount {
    margin: 0 5rem 0 0;
}

.studentAccount__header {
    margin-block-start: 0;
    margin-top: 0;
}

.studentassessments {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin: 2rem 0 0 0;
}

@media (max-width: 1100px) {
    .dashboard--overview {
        display: block;
    }

    .info {
        margin-bottom: 1rem;
    }

    .info__body--cohort {
        display: flex;
        justify-content: space-around;
    }
}


@media (max-width: 780px) {
    .studentassessments {
        flex-direction: column;
    }

    .info__body--cohort {
        display: block;
    }

    .studentAccounts {
        display: block;
    }

    .studentAccount__header {
        margin-block-start: 1rem;
        margin-top: 1rem;
    }
}

.capstones {
    display: flex;
    flex-direction: column;
    margin: 2rem 0 0 0;
}

.assessmentlist {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 0.5rem;
}

.bookassessments {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.bookassessment, .capstoneassessment {
    display: flex;
    flex-direction: row;
    border: 1px dotted lightsteelblue;
    margin: 0 0.25rem 0.25rem 0.25rem;
}

.bookassessment__book {
    flex: 1;
    padding: 0 1rem;
    background-color: rgb(231, 231, 231);
}

.bookassessment__name, .capstoneassessment__course {
    flex: 1;
    padding: 0 1rem;
}

.bookassessment__status {
    flex: 2;
    padding: 0 1rem;
    border-top: 1px dotted rgb(137, 188, 188);
}

.capstoneassessment__statuses {
    flex: 2;
    border-top: 1px dotted rgb(137, 188, 188);
}

.capstoneassessment__status {
    flex: 2;
    padding: 0 1rem;
    border-top: 1px dotted rgb(137, 188, 188);
}

.slack {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-column-gap: 3rem;
    padding: 0 3rem;
    grid-auto-flow: column;
}

.slack__input {
    width: 15rem;
}

.bfiRow {
    display: flex;
    flex-direction: row;
}

.bfiRow__prompt {
    text-align: left;
    flex: 2;
    max-width: 14rem;
}

.bfiRow__input {
    flex: 1;
}

.bfi__input {
    max-width: 2.5rem;
    margin-right: 1rem;
}

.briggs__input {
    max-width: 8rem;
}

.slack__save {
    margin: 1rem 0 0 0;
    padding: 0.2rem 0.3rem;
}

.dialog--statuses {
    position: fixed;
    left: 50%;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 50%;
}

.statusButtons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 2rem 0 2rem 0;
}

.statusButton {
    margin: 0.5rem 1rem;
    height: 3rem;
}

.tagButtons {
    display: grid;
    grid-template-columns: auto auto auto auto;
    margin: 2rem 0 2rem 0;
}

@media (max-width: 1300px) {
    .tagButtons {
        display: grid;
        grid-template-columns: auto auto auto;
        margin: 2rem 0 2rem 0;
    }
}

@media (max-width: 1000px) {
    .tagButtons {
        display: grid;
        grid-template-columns: auto auto;
        margin: 2rem 0 2rem 0;
    }
}

.dialog__close {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    font-size: 0.75rem;
}

.dialog--tags {
    width: 50%;
}

.dialog--settings {
    position: fixed;
    left: 50%;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 50%;
}


.tagDialog__newTag {
    text-align: center;
}

.dialog--projects {
    width: 30%;
}

.capstonePercent {
    padding: 0.5rem 0.5rem 0.25rem 0.5rem;
    background-color: var(--secondary);
    color: var(--darkText);
    margin: 0 -2rem 0 1rem;
    text-align: center;
    font-size: small;
}

.bookButtons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 2rem 0 2rem 0;
}

.projectSelect {
    text-align: center;
}

.toggle--projects {
    padding: 0.25rem 1rem;
    margin: 0 2rem 0 0;
}

.on {
    background-color: rgb(201, 255, 119);
}

.off {
    background-color: rgb(255, 177, 177);
}


.shortcuts {
    border: 1px dotted rgb(222, 209, 174);
    background-color: rgb(252, 252, 243);
    font-size: smaller;
    bottom: 0;
    left: 0;
    position: absolute;
    min-width: 10rem;
    padding: 1rem 2rem;
    color: rgb(159, 151, 144);
    z-index: 0;
}

.shortcuts__header {
    margin: 0 0 0.5rem 0;
}

.shortcut__row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.shortcut__description {
    padding: 0 0 0 1.5rem;
}

.fullCenter {
    margin: 0;
    padding: 10rem 0 0 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}