.form--login {
    display: inline-block;
    margin: 0 auto;
    text-align: left;
}

.form--login > fieldset > input[type="email"],
.form--login > fieldset > input[type="password"] {
    width: 25em;
}

.label--login {
    width: 8rem;
    display: inline-block;
}



.container--login {
    text-align: center;
}

.form--login {
    background-size: 35rem;
    background-position-x: center;
    z-index: 1;
    min-height: 25rem;
    min-width: 45rem;
    padding: 0 5rem;
    background-color: hsla(0,0%,100%,0.75);
    background-blend-mode: overlay;
    background-repeat: no-repeat;
}

.button--close {
    position: relative;
    bottom: -2rem;
}
