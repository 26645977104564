/* Dropdown Button */
/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown__text {
    padding: 0.33rem;
}

/* Dropdown Content (Hidden by Default) */
.dropdown__content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 10rem;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 0rem;
    width: max-content;
}

.dropdownItem {
    font-size: 1rem;
    color: black;
    padding: 1rem 1rem;
    text-decoration: none;
    display: block;
    min-width: 10rem;
}

.dropdownItem--condensed {
    font-size: 0.75rem;
    color: black;
    padding: 0.5rem 1rem;
    text-decoration: none;
    display: block;
    min-width: 10rem;
}

/* Change color of dropdown links on hover */
.dropdown__content a:hover {
    background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown__content {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    background-color: #3e8e41;
}

